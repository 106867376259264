import React from 'react';
import {AnimatePresence} from 'framer-motion';

export const wrapPageElement = ({element}) => (
  <AnimatePresence initial={false} exitBeforeEnter>{element}</AnimatePresence>
);

// transition hossza a layoutban *1000 a ms miatt
const TRANSITION_DELAY = 0.40 * 1000

const scrollPage = (x,y) => {
  if(document){
    document.querySelector("html").style.scrollBehavior = "auto";
    setTimeout(() => {
      window.scrollTo(x,y)
    }, 50);
    setTimeout(() => {
      document.querySelector("html").style.scrollBehavior = "smooth";
    }, TRANSITION_DELAY);
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => scrollPage(0,0), TRANSITION_DELAY)
  } else {
    // if we used the browser's forwards or back button

    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)

  }

  return false
}
